import { environmentBase } from './environment-base';

export const environment = {
  ...environmentBase,
  production: false,
  urlShortenerBaseLoginUrl: {
    //短網址外來要調用成測試版的短網址
    base: 'https://stage-s.aiii.ai',
    default: 'https://stage-s.aiii.ai/r',
  },
  firebase: {
    apiKey: 'AIzaSyDU1KGqlcJ87gfuUH2aerjfhJglkbUpn1c',
    authDomain: 'aiii-developer.firebaseapp.com',
    databaseURL: 'https://aiii-developer.firebaseio.com',
    projectId: 'aiii-developer',
    storageBucket: 'aiii-developer.appspot.com',
    messagingSenderId: '700749202278',
  },
  firebaseConfig: {
    us: {
      apiKey: 'AIzaSyDU1KGqlcJ87gfuUH2aerjfhJglkbUpn1c',
      authDomain: 'aiii-developer.firebaseapp.com',
      databaseURL: 'https://aiii-developer.firebaseio.com',
      projectId: 'aiii-developer',
      storageBucket: 'aiii-developer.appspot.com',
      messagingSenderId: '700749202278',
      region: 'us-central',
    },
    taiwan: {
      apiKey: 'AIzaSyC5K17qCPu1R-m-VKSvH7AG8LSIiDH5HpQ',
      authDomain: 'aiii-developer-tw.firebaseapp.com',
      databaseURL: 'https://aiii-developer-tw.firebaseio.com',
      projectId: 'aiii-developer-tw',
      storageBucket: 'aiii-developer-tw.appspot.com',
      messagingSenderId: '296524928200',
      region: 'asia-east1',
    },
    testAllan: {
      apiKey: 'AIzaSyATSDeV6-Xrv2JWz0qad4QP1tCX36VmHyU',
      authDomain: 'aiii-testallan.firebaseapp.com',
      projectId: 'aiii-testallan',
      storageBucket: 'aiii-testallan.appspot.com',
      messagingSenderId: '648546485642',
      appId: '1:648546485642:web:cbd7b43259a27cd52ef473',
      measurementId: 'G-57V71EX8MY',
    },
    nnhcp: {
      apiKey: 'AIzaSyD7dKxH5wj3HgnICiIzuYZjnLvZIZtfA4U',
      authDomain: 'nn-hcp-stage.firebaseapp.com',
      projectId: 'nn-hcp-stage',
      storageBucket: 'nn-hcp-stage.appspot.com',
      messagingSenderId: '699862024755',
      appId: '1:699862024755:web:8f6d11485f5bba1d23d946',
      measurementId: 'G-ZJKWHHEH17',
    },
  },
  useOtherFirebaseProject: {
    sites: ['testAllan', 'nnhcp'],
    modules: [
      'customer',
      'customer-list',
      'customer-admin',
      'coupon',
      'coupon-online',
      'activity',
      'activity-for-admin-checkin',
      'activity-user-generate-checkin-qr',
      'activity-highlights',
      'article',
      'article-page',
      'article-list',
      'analyzeLabels',
      'demo',
      'notify',
      'login',
      'customer-admin-notify',
      'customer-admin-notify-v2',
      'lottery',
      'survey',
      'survey-demo',
      'share-target-picker',
      'recommendation',
      'live-stream',
      'memberSurvey',
      'memberSurvey-demo',
    ],
  },
  cloudrun: {
    api: 'https://api-v2-5uvz3vc6ga-de.a.run.app',
    platformPush: 'https://platform-push-v1-5uvz3vc6ga-de.a.run.app',
    platformPushAudienceClient: 'https://platform-push-audience-client-5uvz3vc6ga-de.a.run.app',
    basicWebhook: 'https://prod-line-dialogflow-5uvz3vc6ga-de.a.run.app',
    customerV2: 'https://api-customer-v2-5uvz3vc6ga-de.a.run.app',
  },
  gke: {
    internalApi: 'https://stage-internal-api.aiii.ai',
    webhookApi: 'https://stage-webhook.aiii.ai',
  },
};
